<template>
  <div>
    <div class="loading" v-if="isLoading == true">
      <div class="sk-three-bounce">
        <div class="sk-child sk-bounce1"></div>
        <div class="sk-child sk-bounce2"></div>
        <div class="sk-child sk-bounce3"></div>
      </div>
    </div>
    <b-row class="mb-3">
      <b-col sm>
        <div>
          <router-link
            v-if="$can('generate_promotion_code')"
            :to="{ name: 'Add Promotion Code' }"
            class="btn btn-primary"
            ><i class="fa fa-plus"></i> Generate</router-link
          >&nbsp;
        </div>
      </b-col>
    </b-row>
    <b-row class="mb-3">
      <!-- Search -->
      <b-col sm="12" md="6">
        <b-form-group
          class="mb-3"
          label-for="search"
          description="Searchable: promo name, promo code and prefix"
        >
          <template #label>
            Search
          </template>
          <b-input-group>
            <b-input-group-prepend>
              <b-dropdown
                :text="typeSearch"
                v-model="typeSearch"
                variant="secondary"
              >
                <b-dropdown-item
                  v-for="(item, index) in typeSearchs"
                  :key="index"
                  @click="onChangeDropdown(item, 'SEARCH')"
                >
                  {{ item.text }}
                </b-dropdown-item>
              </b-dropdown>
            </b-input-group-prepend>
            <b-form-input
              id="search"
              type="search"
              v-model="filterText"
              :placeholder="placeholder"
              required
            >
            </b-form-input>
            <b-input-group-append>
              <b-button variant="secondary" @click="resetFilter" type="button">
                Reset
              </b-button>
            </b-input-group-append>
          </b-input-group>
        </b-form-group>
      </b-col>
      <!-- Daterange -->
      <b-col sm="12" md="6">
        <b-form-group class="mb-3" label-for="daterange">
          <template #label>
            Date Range <span class="text-danger">*</span>
          </template>
          <b-input-group>
            <b-input-group-prepend>
              <b-dropdown
                :text="typeDate"
                v-model="typeDate"
                variant="secondary"
              >
                <b-dropdown-item
                  v-for="(item, index) in typeDates"
                  :key="index"
                  @click="onChangeDropdown(item, 'DATE')"
                >
                  {{ item.text }}
                </b-dropdown-item>
              </b-dropdown>
            </b-input-group-prepend>
            <range-picker
              id="daterange"
              :start="startDate"
              :end="endDate"
              :type="'clear'"
              :disable="false"
              :autoUpdate="false"
              :timePicker="false"
              @picker="doDateFilter"
            ></range-picker>
            <b-input-group-append>
              <b-button
                type="button"
                variant="secondary"
                @click="resetDateFilter"
                >Reset</b-button
              >
            </b-input-group-append>
          </b-input-group>
        </b-form-group>
      </b-col>
      <!-- Code Type -->
      <b-col sm="12" md="6">
        <b-form-group id="codeType" label="Code Type" label-for="codeType">
          <b-form-select
            id="codeType"
            v-model="codeType"
            :options="[
              { value: '', text: 'All' },
              { value: '0', text: 'General' },
              { value: '1', text: 'Unique' }
            ]"
          >
          </b-form-select>
        </b-form-group>
      </b-col>
      <!-- User Type -->
      <b-col sm="12" md="6">
        <b-form-group id="userType" label="User Type" label-for="userType">
          <b-form-select
            id="userType"
            v-model="userType"
            :options="[
              { value: '', text: 'All' },
              { value: '0', text: 'All User' },
              { value: '1', text: 'New User' },
              { value: '2', text: 'First Time Buyer' },
              { value: '3', text: 'Last Time Buy' },
              { value: '4', text: 'Register At' }
            ]"
          >
          </b-form-select>
        </b-form-group>
      </b-col>
      <!-- Status -->
      <b-col sm="12" md="6">
        <b-form-group id="statusType" label="Status" label-for="statusType">
          <b-form-select
            id="statusType"
            v-model="statusType"
            :options="[
              { value: '', text: 'All' },
              { value: 'created', text: 'Created' },
              { value: 'active', text: 'Active' },
              { value: 'inactive', text: 'Inactive' }
            ]"
          >
          </b-form-select>
        </b-form-group>
      </b-col>
      <!-- Button Apply -->
      <b-col class="mt-3" sm="12">
        <b-button
          :disabled="stateButton"
          :style="buttonStyle"
          class="mb-2"
          @click="doFilter"
        >
          Apply Filter
        </b-button>
        <p class="mb-3" :style="{ color: 'rgba(115, 129, 143, 1)' }">
          Click button 'Apply Filter' to apply the filter
        </p>
      </b-col>
    </b-row>
    <vuetable
      ref="vuetable"
      :api-mode="false"
      :fields="fields"
      :per-page="limit"
      :data-manager="dataManager"
      pagination-path="pagination"
      :no-data-template="isParams ? defaultTemplate : noDataTemplate"
      @vuetable:pagination-data="onPaginationData"
    >
      <template slot="code-slot" slot-scope="prop">
        <b-badge pill variant="dark" class="badge--code p-3">{{
          prop.rowData.code
        }}</b-badge>
      </template>
      <template slot="period-slot" slot-scope="prop">
        <span
          :style="[
            timeNow >= prop.rowData.end_to
              ? { color: 'red' }
              : { color: 'green' }
          ]"
          >{{ prop.rowData.start_from }}</span
        >
        <br />
        <span
          :style="[
            timeNow >= prop.rowData.end_to
              ? { color: 'red' }
              : { color: 'green' }
          ]"
        >
          -
        </span>
        <br />
        <span
          :style="[
            timeNow >= prop.rowData.end_to
              ? { color: 'red' }
              : { color: 'green' }
          ]"
          >{{ prop.rowData.end_to }}</span
        >
      </template>
      <template slot="status" slot-scope="prop">
        <span
          :style="[
            prop.rowData.status == 'active'
              ? { color: 'green' }
              : prop.rowData.status == 'inactive'
              ? { color: 'red' }
              : { color: 'inherit' }
          ]"
          >{{
            prop.rowData.status
              ? prop.rowData.status.replace(
                  /^./,
                  prop.rowData.status[0].toUpperCase()
                )
              : "-"
          }}</span
        >
      </template>
      <template slot="index_view" slot-scope="prop">
        <span>{{ prop.rowData.index_view || "-" }}</span>
      </template>
      <template slot="actions-slot" slot-scope="prop">
        <div
          class="custom-actions"
          style="display: flex; flex-direction: column"
        >
          <button
            class="btn btn-info mb-2"
            v-if="$can('detail_promotion_code')"
            @click="onAction('detail-item', prop.rowData, prop.rowIndex)"
          >
            Detail
          </button>
          <button
            :class="
              prop.rowData.status === true
                ? 'btn btn-warning mb-2'
                : 'btn btn-success mb-2'
            "
            v-if="$can('detail_promotion_code') && prop.rowData.code_type === 0"
            @click="onAction('activate-item', prop.rowData, prop.rowIndex)"
          >
            {{ prop.rowData.status === true ? "Hide" : "Show" }}
          </button>
          <div
            class="mb-2"
            style="display: flex; justify-content: space-between; align-items: center"
          >
            <button
              v-if="$can('delete_promotion_code')"
              class="btn btn-danger"
              @click="onAction('delete-item', prop.rowData)"
            >
              <i class="fa fa-trash-o"></i>
            </button>
            <button
              class="btn btn-primary"
              v-if="$can('edit_promotion_code')"
              @click="onAction('edit-item', prop.rowData, prop.rowIndex)"
            >
              <i class="fa fa-pencil"></i>
            </button>
          </div>
          <button
            :disabled="isLoadingExport && exportId == prop.rowData.id"
            class="btn btn-success"
            v-if="$can('detail_promotion_code')"
            @click="onAction('export-item', prop.rowData, prop.rowIndex)"
          >
            <div
              class="d-inline-flex sk-three-bounce custom-loading-bounce"
              v-if="isLoadingExport && exportId == prop.rowData.id"
            >
              <div class="sk-child sk-bounce1"></div>
              <div class="sk-child sk-bounce2"></div>
              <div class="sk-child sk-bounce3"></div>
            </div>
            <span class="mr-2" v-else>Export</span>
          </button>
        </div>
      </template>
    </vuetable>
    <div v-show="isParams" class="vuetable-pagination ui basic segment grid">
      <div class="wrapper-pagination-custom">
        <div class="pagination-custom-info">
          <p>{{ this.paginationInfo }}</p>
        </div>
        <div class="pagination-custom-button">
          <button :disabled="currentPage === 1" @click="onMovePage('first')">
            <i
              v-if="currentPage === 1"
              class="fa fa-angle-double-left disabled"
            ></i>
            <i v-else class="fa fa-angle-double-left"></i>
          </button>
          <button :disabled="currentPage === 1" @click="onMovePage('prev')">
            <i v-if="currentPage === 1" class="fa fa-angle-left disabled"></i>
            <i v-else class="fa fa-angle-left"></i>
          </button>
          <div>{{ this.currentPage }}</div>
          <button :disabled="data.length < 10" @click="onMovePage('next')">
            <i v-if="data.length < 10" class="fa fa-angle-right disabled"></i>
            <i v-else class="fa fa-angle-right"></i>
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import _ from "lodash";
import accounting from "accounting";
import Vue from "vue";
import Vuetable from "vuetable-2/src/components/Vuetable";
import moment from "moment";
import axios from "axios";

let startDate = "";
let endDate = "";
Vue.use(Vuetable);

export default {
  components: {
    Vuetable
  },
  prop: {
    rowData: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      count: 0,
      page: 1,
      currentPage: 1,
      paginationInfo: "No relevant data",
      limit: 10,
      data: [],
      typeSearch: "Promo Name",
      typeDate: "Create At",
      typeSearchs: [
        {
          value: "Promo Name",
          text: "Promo Name",
          placeholder: "Search by Promo Name"
        },
        {
          value: "Promo Code",
          text: "Promo Code",
          placeholder: "Search by Promo Code"
        },
        { value: "Prefix", text: "Prefix", placeholder: "Search by Prefix" }
      ],
      typeDates: [
        {
          value: "Create At",
          text: "Create At"
        },
        {
          value: "Used At",
          text: "Used At"
        }
      ],
      placeholder: "Search by Promo Name",
      statusOptions: [
        { text: "Pilih Status Transaksi", value: "" },
        { text: "Dibatalkan", value: "cancelled" },
        { text: "Success", value: "success" },
        { text: "Expired", value: "expired" },
        { text: "Menunggu Pembayaran", value: "pending" }
      ],
      status: "",
      startDate: "",
      endDate: "",
      isLoading: false,
      isLoadingExport: false,
      exportId: "",
      filterText: "",
      codeType: "",
      userType: "",
      statusType: "",
      timeNow: moment().format("YYYY-MM-DD hh:mm:ss"),
      organization: "",
      module: "",
      organizationOptions: [],
      errors: {
        code: "",
        message: "",
        status: ""
      },
      apiUrl:
        process.env.VUE_APP_SECRET +
        process.env.VUE_APP_CONFIG +
        `promotion-code`,
      HttpOptions: {
        headers: {
          Accept: "application/json",
          Authorization: "Bearer " + localStorage.getItem("access_token")
        }
      },
      sort: "promotion_codes.created_at|desc",
      sortOrder: [
        {
          field: "created_at",
          sortField: "promotion_codes.created_at",
          direction: "desc"
        }
      ],
      moreParams: {},
      fields: [
        {
          name: "created_at",
          title: "Created At"
        },
        {
          name: "code-slot",
          title: "Promo Code"
        },
        {
          name: "name",
          title: "Promo Name"
        },
        {
          name: "period-slot",
          title: "Promo Period"
        },
        {
          name: "user_type",
          title: "User Type"
        },
        {
          name: "quota",
          title: "Quota",
          sortField: "promotion_codes.quota"
        },
        {
          name: "status",
          title: "Status"
        },
        {
          name: "min_amount",
          title: "Minimum Transaction",
          formatter: value => this.formatNumber(value)
        },
        {
          name: "amount",
          title: "Promotion Amount"
        },
        {
          name: "index_view",
          title: "Priority Ordering",
          sortField: "promotion_codes.index_view"
        },
        {
          name: "actions-slot",
          title: "Actions",
          titleClass: "center aligned",
          dataClass: "center aligned"
        }
      ],
      noDataTemplate:
        '<div class="text-center d-flex align-items-center justify-content-center" style="height: 120px;"><p>Use filter to show your data.</p></div>',
      defaultTemplate:
        '<div class="text-center d-flex align-items-center justify-content-center flex-column" style="height: 120px;"><p><strong>No results found.</strong></p><p>Please adjust your filter or search keywords</p></div>'
    };
  },
  computed: {
    stateButton() {
      const mandatory = this.startDate !== "" && this.endDate !== "";
      const filters =
        this.startDate == "" &&
        this.endDate == "" &&
        this.filterText == "" &&
        this.codeType == "" &&
        this.userType == "" &&
        this.status == "";
      const params =
        Object.entries(this.moreParams).filter(([key, value]) => value)
          .length == 0;

      return !mandatory || (filters && params);
    },
    buttonStyle() {
      const state = !this.stateButton;
      const styles = {
        background: state ? "#7565F6" : "#c8ced3",
        color: state ? "#FFFFFF" : "inherit"
      };

      return styles;
    },
    isParams() {
      const params =
        Object.entries(this.moreParams).filter(([key, value]) => value)
          .length == 0;

      return !params;
    }
  },
  watch: {
    // eslint-disable-next-line no-unused-vars
    data(newVal, oldVal) {
      this.$refs.vuetable.refresh();
    }
  },
  created() {
    axios.defaults.headers.common[
      "Authorization"
    ] = `Bearer ${localStorage.getItem("access_token")}`;
    axios
      .get(
        process.env.VUE_APP_SECRET +
          "admin/v2/command/organization-command?reseller=true"
      )
      .then(response => {
        let data = response.data.data;

        let attr = [{ text: "Pilih Organisasi", value: "" }];
        data.map(v => {
          return attr.push({ text: v.text, value: v.value });
        });
        this.organizationOptions = attr;
      });
  },
  methods: {
    onFetch() {
      this.isLoadingTable = true;
      const newParams = Object.entries(this.moreParams)
        .filter(([key, value]) => {
          if (!this.filterText && key == "filter_field") return;
          return value;
        })
        .map(([key, value]) => `${key}=${value}`)
        .join("&");

      this.$http
        .get(
          `promotion-code?sort=${encodeURIComponent(this.sort)}&page=${
            this.page
          }&per_page=10&${newParams}`
        )
        .then(response => {
          const datas = response.data.data;
          const datasCount = datas.length;
          const startIndex = (response.data.current_page - 1) * this.limit;
          const endIndex = Math.min(
            startIndex + this.limit,
            datasCount > 0 ? datasCount + startIndex : this.limit
          );

          this.data = datas;
          this.currentPage = response.data.current_page;
          this.paginationInfo =
            datasCount > 0
              ? `Displaying ${startIndex + 1} to ${endIndex}`
              : "No relevant data";

          this.isLoadingTable = false;
        })
        .catch(err => {
          this.isLoadingTable = false;
          this.handleLoadError(err);
        });
    },
    exportTable() {
      this.isLoading = true;

      // if (this.startDate != "" && this.endDate != "") {
      //   startDate = this.startDate;
      //   endDate = this.endDate;
      // } else {
      //   endDate = this.$setDate.format("YYYY-MM-DD");
      //   startDate = this.$setDate.subtract(6, "days").format("YYYY-MM-DD");
      // }

      const filters = Object.entries(this.moreParams)
        .filter(([key]) => {
          return key !== "module" && key !== "code_type";
        })
        .map(([key, value]) => {
          return `${key}=${value}`;
        });
      const newParams = filters.length > 0 ? `?${filters.join("&")}` : "";

      this.$http
        .get(`data-promotion-code${newParams}`)
        .then(result => {
          this.isLoading = false;
          const exportPath = result.data.meta.data;
          window.location.href = exportPath;
        })
        .catch(error => {
          if (error.response) {
            this.isLoading = false;
            this.errors.code = error.response.status;
            this.errors.status = error.response.data.meta.code;
            this.errors.headers = error.response.headers;
          }
        });
    },
    doFilter() {
      this.page = 1;
      this.moreParams = {
        start_from: this.startDate,
        end_to: this.endDate,
        date_field: this.typeDate == "Create At" ? "created_at" : "used_at",
        filter_value: encodeURIComponent(this.filterText),
        filter_field:
          this.typeSearch == "Promo Name"
            ? "name"
            : this.typeSearch == "Promo Code"
            ? "code"
            : this.typeSearch == "Prefix"
            ? "prefix"
            : "",
        code_type: this.codeType,
        user_type: this.userType,
        status: this.statusType
      };
      this.onFetch();
    },
    doDateFilter(value) {
      this.startDate = value.startDate;
      this.endDate = value.endDate;
    },
    resetFilter() {
      this.filterText = ""; // clear the text in text input
      this.$events.fire("filter-reset");
    },
    weight(value) {
      return value + " Gram";
    },
    handleLoadError(error) {
      this.errors.code = error.response.data.meta.code;
      this.errors.message = error.response.data.meta.message;
      this.errors.status = error.response.data.meta.code;
      if (this.errors.code == 401) {
        if (localStorage.getItem("access_token") != null) {
          localStorage.removeItem("access_token");
          this.$swal
            .fire(
              "Your session expired!",
              "Your session has expired. Please login again to access this page!",
              "error"
            )
            .then(() => {
              this.$router.push("/login");
            });
        }
      } else if (this.errors.code == 403) {
        this.$router.push("/403");
      } else if (this.errors.code == 500) {
        this.$router.push("/500");
      }
    },
    resetDateFilter() {
      this.startDate = "";
      this.endDate = "";
      this.$events.$emit("date-filter-reset");
    },
    formatNumber(value) {
      return "Rp. " + accounting.formatNumber(value, 0, ".", ",");
    },
    onChangePage(page) {
      this.$refs.vuetable.changePage(page);
    },
    onPaginationData(paginationData) {
      console.log("paginationData", paginationData);
      // this.$refs.pagination.setPaginationData(paginationData)
      // this.$refs.paginationInfo.setPaginationData(paginationData)
    },
    onAction(action, data) {
      if (action == "detail-item") {
        this.$router.push({
          name: "Detail Promo Code",
          params: { id: data.id }
        });
      } else if (action === "edit-item") {
        this.$router.push({
          name: "Edit Promo Code",
          params: { id: data.id }
        });
      } else if (action === "activate-item") {
        this.$swal
          .fire({
            title: "Confirmation",
            html: `<p>Apakah kamu yakin ${
              data.status === true
                ? "<strong>Hide</strong>"
                : "<strong>Show</strong>"
            } kode Promo <strong>${data.code} - ${data.name}?</strong></p>`,
            type: "warning",
            showCancelButton: true,
            confirmButtonText: "Ya",
            cancelButtonText: "Batal"
          })
          .then(result => {
            if (result.value) {
              this.$http
                .post(`activate-promotion-code/` + data.id)
                .then(() => {
                  this.$swal
                    .fire({
                      title: "Success!",
                      html: `<p>Berhasil ${
                        data.status === true
                          ? "<strong>Hide</strong>"
                          : "<strong>Show</strong>"
                      } kode Promo <strong>${data.code} - ${
                        data.name
                      }</strong></p>`,
                      type: "success"
                    })
                    .then(() => {
                      location.reload();
                    });
                })
                .catch(error => {
                  if (error.response) {
                    this.errors.code = error.response.status;
                    this.errors.message = error.response.data.meta.message;
                    this.errors.status = error.response.data.meta.code;
                  }
                });
            } else if (result.dismiss === this.$swal.DismissReason.cancel) {
              this.$swal.fire(
                "Cancelled",
                "Promotion Code was successfully canceled for Show/Hide!",
                "error"
              );
            }
          });
      } else if (action === "export-item") {
        this.isLoadingExport = true;
        this.exportId = data.id;

        const payload = {
          code: data.code,
          code_type: data.code_type,
          date_field: this.typeDate == "Create At" ? "created_at" : "used_at",
          start_from: this.startDate,
          end_to: this.endDate
        };
        const newParams = Object.entries(payload)
          .map(([key, value]) => {
            return `${key}=${value}`;
          })
          .join("&");

        this.$http
          .get(`data-promotion-code?${newParams}`)
          .then(result => {
            this.isLoadingExport = false;
            this.exportId = "";

            const exportPath = result.data.meta.data;
            window.location.href = exportPath;
          })
          .catch(error => {
            if (error.response) {
              this.isLoadingExport = false;
              this.exportId = "";

              this.errors.code = error.response.status;
              this.errors.status = error.response.data.meta.code;
              this.errors.headers = error.response.headers;
            }
          });
      } else {
        this.$swal
          .fire({
            title: "Are you sure?",
            text: "You will not be able to recover this Promotion Code!",
            type: "warning",
            showCancelButton: true,
            confirmButtonText: "Yes, delete it!",
            cancelButtonText: "No, keep it"
          })
          .then(result => {
            if (result.value) {
              this.$http
                .delete(`promotion-code/` + data.id)
                .then(() => {
                  this.$swal
                    .fire(
                      "Deleted!",
                      "Promotion Code has been deleted.",
                      "success"
                    )
                    .then(() => {
                      location.reload();
                    });
                })
                .catch(error => {
                  if (error.response) {
                    this.errors.code = error.response.status;
                    this.errors.message = error.response.data.meta.message;
                    this.errors.status = error.response.data.meta.code;
                  }
                });
            } else if (result.dismiss === this.$swal.DismissReason.cancel) {
              this.$swal.fire(
                "Cancelled",
                "Promotion Code was successfully canceled for deletion!",
                "error"
              );
            }
          });
      }
    },
    onFilterReset() {
      this.moreParams = Object.fromEntries(
        Object.entries(this.moreParams).filter(([key]) => key !== "filter")
      );
    },
    onDateFilterReset() {
      this.moreParams = Object.fromEntries(
        Object.entries(this.moreParams).filter(([key]) => {
          return key !== "start_from" && key !== "end_to";
        })
      );
    },
    onChangeDropdown(item, type) {
      if (type == "DATE") {
        this.typeDate = item.value;
      } else {
        this.typeSearch = item.value;
        this.placeholder = item.placeholder;
      }
    },
    onMovePage(eventData) {
      if (eventData === "first") {
        this.page = 1;
        this.onFetch();
      } else if (eventData === "prev") {
        this.page--;
        this.onFetch();
      } else {
        this.page++;
        this.onFetch();
      }
    },
    dataManager(sortOrder, pagination) {
      let local = this.data;

      if (sortOrder.length > 0) {
        const newSort = `${sortOrder[0].sortField}|${sortOrder[0].direction}`;

        if (this.sort !== newSort) {
          this.sort = newSort;
          this.onFetch();
        }
      }

      pagination = this.$refs.vuetable.makePagination(local.length, this.limit);

      let from = pagination.from - 1;
      let to = from + this.limit;

      return {
        pagination: pagination,
        data: local.length > 0 ? _.slice(local, from, to) : []
      };
    }
  },
  mounted() {
    this.$events.$on("filter-reset", () => this.onFilterReset());
    this.$events.$on("date-filter-reset", () => this.onDateFilterReset());
  }
};
</script>
<style>
/* Absolute Center Spinner */
.loading {
  position: fixed;
  z-index: 999;
  height: 2em;
  width: 2em;
  overflow: visible;
  margin: auto;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}

/* Transparent Overlay */
.loading:before {
  content: "";
  display: block;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.3);
}

/* Pagination Custom */
.wrapper-pagination-custom {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #f9fafb;
}

.pagination-custom-button {
  display: flex;
}

.pagination-custom-button button {
  width: 28px;
  height: 47px;
  padding: 13px 16px;
  font-size: 1em;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #ffffff;
  border: solid rgba(0, 0, 0, 0.3);
}

.pagination-custom-button button:first-child {
  padding: 13px 16px 13px 30px;
  border-radius: 4px 0 0 4px;
  border-width: 1px 0 1px 1px;
}

.pagination-custom-button button:nth-child(2),
.pagination-custom-button button:last-child {
  font-size: 1.3em;
  border-width: 1px;
}

.pagination-custom-button button:last-child {
  border-radius: 0 4px 4px 0;
}

.pagination-custom-button button i.disabled {
  opacity: 0.6;
}

.pagination-custom-button div {
  width: 28px;
  height: 47px;
  font-size: 1em;
  font-weight: 500;
  background: #ffffff;
  border: solid rgba(0, 0, 0, 0.3);
  border-width: 1px 0 1px 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

/* Loading Bounce */
.custom-loading-bounce {
  width: 60px !important;
  height: 18px !important;
  margin: 0 !important;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 4px;
}

.custom-loading-bounce .sk-child {
  width: 6px !important;
  height: 6px !important;
  background: #ffffff !important;
}
</style>
<style src="spinkit/scss/spinkit.scss" lang="scss" />
